import GridImage01 from "../../../../src/posts/2019-01-24-a-new-phase-of-our-travels/images/grid-images/image-01.jpg";
import GridImage02 from "../../../../src/posts/2019-01-24-a-new-phase-of-our-travels/images/grid-images/image-02.jpg";
import GridImage03 from "../../../../src/posts/2019-01-24-a-new-phase-of-our-travels/images/grid-images/image-03.jpg";
import GridImage04 from "../../../../src/posts/2019-01-24-a-new-phase-of-our-travels/images/grid-images/image-04.jpg";
import GridImage05 from "../../../../src/posts/2019-01-24-a-new-phase-of-our-travels/images/grid-images/image-05.jpg";
import GridImage06 from "../../../../src/posts/2019-01-24-a-new-phase-of-our-travels/images/grid-images/image-06.jpg";
import { Video } from "../../../../src/components/Complete";
import GetImages from "../../../../src/components/GetImages.js";
import * as React from 'react';
export default {
  GridImage01,
  GridImage02,
  GridImage03,
  GridImage04,
  GridImage05,
  GridImage06,
  Video,
  GetImages,
  React
};